import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";
import Link from "./link";

const ImageSection = ({ title, images, text, order }) => {
	return (
		<Container>
			<Row className="justify-content-center">
				<Col
					style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)" }}
					className="p-lg-6 p-2 py-4 px-md-4"
					xl={10}
				>
					<Row>
						<Col>
							<h1 className="text-center mb-1">{title}</h1>
							<hr
								className="bg-primary mx-auto mt-0"
								style={{ height: "2px", width: "6rem" }}
							/>
						</Col>
					</Row>
					<Row className="pt-4">
						<Col
							className={`${
								order ? "ps-lg-5" : "text-end pe-lg-5"
							} mb-3 mb-lg-0 `}
							lg={{ span: 6, order: order }}
						>
							{images}
						</Col>
						<Col lg={6}>
							{text}
							<Button
								className="px-4 fw-bold w-100 w-md-auto cta-btn"
								variant="primary"
								as={Link}
								to="/contact-us"
							>
								Get a quote
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default ImageSection;
