import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Layout from "../../components/layout";
import ServiceHero from "../../components/service-hero";
import ImageSection from "../../components/image-section";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import ReviewSection from "../../components/review-section";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import Certifications from "../../components/certifications";

const MaintenancePage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Maintenance-Image-New" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			EHIImg: wpMediaItem(title: { eq: "Josh-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			FPRImg: wpMediaItem(title: { eq: "Rewire-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const EHIImg = data.EHIImg?.localFile.childImageSharp.gatsbyImageData;
	const FPRImg = data.FPRImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Services",
				item: {
					url: `${siteUrl}/services`,
					id: `${siteUrl}/services`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Maintenance",
				item: {
					url: `${siteUrl}/services/maintenance`,
					id: `${siteUrl}/services/maintenance`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Maintenance | Denis Bonnici Electricians Ltd"
				description="Please call us to discuss your requirements for all aspects of electrical work in the Domestic and Commercial area."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/services/maintenance`,
					title: "Maintenance | Denis Bonnici Electricians Ltd",
					description:
						"Please call us to discuss your requirements for all aspects of electrical work in the Domestic and Commercial area.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@denisbonnici",
					site: "@denisbonnici",
					cardType: "summary",
				}}
			/>
			<ServiceHero backgroundImage={heroImg} title="Maintenance" />
			<section className="pt-md-7 ">
				<ImageSection
					title="General Electrical Works"
					images={
						<GatsbyImage
							className="w-md-100 w-lg-auto"
							image={EHIImg}
							alt={data.EHIImg?.altText}
						/>
					}
					text={
						<span>
							<p>
								Please call us to discuss your requirements for all aspects of
								electrical work in the Domestic and Commercial area. We attend
								to fault finding and investigations, repairs and replacements of
								light fittings and sockets, cookers/hobs/hoods, extractor
								fans/switches, bonding {"(earthing to pipework)"} and much more…
							</p>
							<p>
								We can advise on lighting upgrades, energy efficiency, electric
								heating, garden/outdoor supplies and new smart technologies.
							</p>
						</span>
					}
				/>
			</section>
			<section className="py-md-7 py-4">
				<ImageSection
					title="Full or Partial Rewires"
					images={
						<GatsbyImage
							className="w-md-100 w-lg-auto"
							image={FPRImg}
							alt={data.FPRImg?.altText}
						/>
					}
					text={
						<span>
							<p>
								If you require a full or partial rewire call us for a quote. We
								would usually advise an EICR{" "}
								{"(Electrical Installation Condition Report)"} to verify if a
								full or partial rewire would be required.
							</p>
							<p>
								New installations: Kitchens, Bathrooms, Extensions, Loft
								Conversions and Offices – Please call us for a quotation.
							</p>
						</span>
					}
					order="last"
				/>
			</section>
			<section className="pt-6 pb-7 ">
				<Certifications />
			</section>
			<section className="pb-md-7 pb-4">
				<ReviewSection />
			</section>
		</Layout>
	);
};

export default MaintenancePage;
